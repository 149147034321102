<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Прекратить действие версии {{ selected.vcroutesVersion }}
        маршрута № {{ selected.vcroutesRoutecode }}
      </v-card-title>
      <v-divider />

      <v-card-text v-if="check" class="pt-4">
        <p>Укажите дату прекращения действия версии маршрута</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>

      <v-card-text v-else class="pt-4">
        Действие доступно только для статуса «Утвержден»
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="closeQuery"
              :loading="loading"
              :disabled="!date"
            >
              Прекратить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <component
      :is="component"
      :messages="messages"
      :collection="this.collection"
      :selected="this.selected"
      :routeId="this.selected.vcroutesId"
      :verId="this.selected.vcroutesVerid"
      :date="this.date"
      :userId="this.selected._sec_attrsUserid"
      :tenantId="this.selected._sec_attrsTenantid"
      @onApply="closeQuery"
    ></component>
  </v-dialog>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

const APPROVE_STATUS_GUID = '3a928e12-eca1-4be3-bff7-df05b5155bde';

import Vue from "vue";

export default {
  name: "CloseRouteVersion",
  props: {
    collection: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    dialog: true,
    loading: false,
    component: null,
    item: null,
    block: false,
    selected: null,
    check:false,
    messages: [],
    components: [
      {name: 'CloseErrors',},
      {name: 'CloseWarnings',}
    ],
    dayOfWeekToFormat: () => '',
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;
    if (!this.selected) {
      this.dialog = false;
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }

    if(this.selected.vcroutesVerstatus === APPROVE_STATUS_GUID){
       this.check = true;
    } else {
      jet.msg({
        text: 'Действие доступно только для статуса «Утвержден».',
        color: 'warning'
      });
    }

    if(this.selected.vcroutesVerstart > this.date){
      jet.msg({
        text: 'Дата окончания не должна быть меньше даты начала.',
        color: 'warning'
      });
    }
  },
  methods: {
    async closeQuery(messages) {
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
      this.loading = true;
      let component = '';
      let optionsQuery = {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.CloseRouteVersionWeb',
        params: {
          in_routeId: this.selected.vcroutesId,
          in_verId: this.selected.vcroutesVerid,
          in_date: this.date,
          in_userId: userId, //this.selected._sec_attrsUserid,
          in_tenantId: tenantId, //this.selected._sec_attrsTenantid,
          in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
          in_mode: 1,
        }
      }
      try {
        let resp = await jet.http.post(optionsQuery);
        if (resp.error) {
          throw resp.error;
        }

        const arr = resp.result.data;
        for (var i = 0; i < arr.length; i++){
          var item = arr[i];
          (this.messages).push(JSON.parse(item[0]));
          
          if (this.messages[i].response.is_block) {
            component = this.components[0];
            break;
          } else {
            component = this.components[1];
          }
        }

        this.component = component.name;
        if(this.component){
          const checkImport = import(`./components/${this.component}`);
          //TODO: разобраться почему catch выбрасывается раньше, чем выводится сообщение об отсутсвии компонента
          if (!checkImport) {
            jet.msg({
              text: `Компонент ${this.component} не найден!`,
              color: 'warning'
            });
          } else {
            Vue.component(
              this.component,
              () => import('./components/' + this.component)
            );
          }
        }else{
          optionsQuery = {
            type: 'query',
            query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.CloseRouteVersionWeb',
            params: {
              in_routeId: this.selected.vcroutesId,
              in_verId: this.selected.vcroutesVerid,
              in_date: this.date,
              in_userId: userId, //this.selected._sec_attrsUserid,
              in_tenantId: tenantId, //this.selected._sec_attrsTenantid,
              in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
              in_mode: 2,
            }
          }
          resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Прекращение действия версии ${this.selected.vcroutesVersion} маршрута № ${this.selected.vcroutesRoutecode} ${this.selected.vcroutesRoutename} выполнено успешно!`,
            type: 'warning'
          });
        }
      } catch (e) {
        jet.msg({
          text: 'Не удалось прекратить действие версии маршрута.',
          color: 'warning'
        });
        console.error('CloseRouteVersion: closeQuery()', e);
        return;
      }
      this.loading = false;
      this.dialog = false;
      this.collection.refresh(this.selected.vcroutesId);
    }
  }
}
</script>

